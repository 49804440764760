import Swal from 'sweetalert2'

const successIconHtml = '<i class="v-icon mdi mdi-checkbox-marked-circle success" />'
const errorIconHtml = '<i class="v-icon mdi mdi-alert-octagon error" />'
const warningIconHtml = '<i class="v-icon mdi mdi-message-alert warning" />'
const questionIconHtml = '<i class="v-icon mdi mdi-help-circle question" />'

export const showSuccessDialog = async (title, text = '', type = 'text') => {
  return Swal.fire({
    title,
    text: type === 'text' ? text : '',
    html: type === 'html' ? text : '',
    confirmButtonColor: '#2196f3',
    iconHtml: successIconHtml,
    allowOutsideClick: false,
    customClass: {
      title: 'success',
    },
  })
}

export const showErrorDialog = async (title, text = '', type = 'text') => {
  return Swal.fire({
    title,
    text: type === 'text' ? text : '',
    html: type === 'html' ? text : '',
    iconHtml: errorIconHtml,
    confirmButtonColor: '#ff5252',
    allowOutsideClick: false,
    customClass: {
      title: 'error',
    },
  })
}

export const showWarningDialog = async (title, text = '', type = 'text') => {
  return Swal.fire({
    title,
    text: type === 'text' ? text : '',
    html: type === 'html' ? text : '',
    iconHtml: warningIconHtml,
    confirmButtonColor: '#e65100',
    allowOutsideClick: false,
    customClass: {
      title: 'warning',
    },
  })
}

export const showQuestionDialog = async (
  title,
  text = '',
  confirmButtonText = 'Yes',
  cancelButtonText = 'No',
  type = 'text',
) => {
  return await Swal.fire({
    title,
    text: type === 'text' ? text : '',
    html: type === 'html' ? text : '',
    iconHtml: questionIconHtml,
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonColor: '#2196f3',
    cancelButtonColor: '#ff5252',
    confirmButtonText,
    cancelButtonText,
    customClass: {
      title: 'question',
    },
  })
}
